import { useState, useEffect } from 'react';
import convertTextToSpeech from './ConvertTextToSpeech';

const useSpeechRecognition = (initialWordsToCompare = []) => {
  const [current, setCurrent] = useState("");
  const [full, setFull] = useState("");
  const [lastestThreeWords, setLastestThreeWords] = useState("")
  const [isRecording, setIsRecording] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [attempts, setAttempts] = useState(3);
  const [wordsToCompare, setWordsToCompare] = useState(initialWordsToCompare);
  const [matchedWords, setMatchedWords] = useState([]);
  const [inactivityTimer, setInactivityTimer] = useState(null);

  const startRecording = () => {
    console.log("Start");
    setAttempts((prevValue) => prevValue - 1);
    const newRecognition = new window.webkitSpeechRecognition();
    newRecognition.continuous = true;
    newRecognition.interimResults = true;
    newRecognition.onstart = () => {
      setIsRecording(true);
      clearTimeout(inactivityTimer); // Clear any existing timer
      // Don't set the inactivity timer here
      window.alert('Recording started. Press OK to continue.');
    };
  
    newRecognition.onresult = (event) => {
      let interimTranscript = '';
      let finalTranscript = '';
  
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript + ' ';
        } else {
          interimTranscript += transcript + ' ';
        }
      }
      setCurrent(interimTranscript);
      setFull((prevText) => {
        setFull(prevText + finalTranscript);
      });
    };
  
    newRecognition.onend = async () => {
      setIsRecording(false);
      setInactivityTimer(setTimeout(stopRecording, 5000)); // Set the inactivity timer here
      const reattempt_instruction= "Please click on the microphone button to start recording again. You have," + attempts + ", attempts left";
      await convertTextToSpeech(reattempt_instruction)
      window.alert('Recording stopped. Press OK to continue.');
    };
  
    setInactivityTimer(setTimeout(stopRecording, 5000)); // Set the inactivity timer here
    setRecognition(newRecognition);
    newRecognition.start();
    
    
  };
  
  const stopRecording = () => {
    console.log("Stop");
    if (recognition) {
      recognition.stop();
      clearTimeout(inactivityTimer);
    }
  };
  useEffect(() => {
    setWordsToCompare(initialWordsToCompare);
  }, [initialWordsToCompare]);

  useEffect(() => {
    console.log("Full was called")
    if(full.split(" ").length >= 3) {
      let words = full.split(" ");
      let lastThreeWords = words.slice(-4).join(" ");
      console.log("From latest three", words)
      setLastestThreeWords(lastThreeWords)
    }
  }, [full]);

  useEffect(() => {
    console.log("Comparing words: ", wordsToCompare);
    console.log("useEffect in useSpeechRecognition is running");
    const lowercaseFull = full ? full.toLowerCase() : ''; // Ensure full is not undefined
    const matched = wordsToCompare.filter(word => lowercaseFull.includes(word.toLowerCase()));
    setMatchedWords(matched);
  }, [full, wordsToCompare]);
  

  return { current, full, isRecording, startRecording, stopRecording, attempts, matchedWords, lastestThreeWords };
};

export default useSpeechRecognition;
