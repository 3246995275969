import React, { useState, useEffect, Link } from 'react';
import RecordRTC from 'recordrtc';
import './word-recall.css';
import axios from 'axios';
import Header from './header';
import Footer from './Footer';
import useSpeechRecognition from '../utils/useSpeechRecognition';
import convertTextToSpeech from '../utils/ConvertTextToSpeech';
const TestThree = () => {
  const [recording, setRecording] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [totalPoints, setTotalPoints] = useState(0);
  const [attempts, setAttempts] = useState(3);
  const [timer, setTimer] = useState(null);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [hours, setHours] = useState(0);
  const [testStarted, setTestStarted] = useState(false);
  const [speechFinished, setSpeechFinished] = useState(false);
  const [recordingStopped, setRecordingStopped] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [recognizedText, setRecognizedText] = useState('');
  const [isRecordingActive, setIsRecordingActive] = useState(false);
  const [instructionsBeingRead, setInstructionsBeingRead] = useState(false);
  const [selectedWords, setSelectedWords] = useState();
  const [user_id, setUserId] = useState('');
  const [clock_score, setClockScore] = useState(0);
  const [parsedSelectedWords, setParsedSelectedWords] = useState();
  const speechRecognition = useSpeechRecognition(parsedSelectedWords);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setUserId((urlParams.get('uid')));
    const scoreFromParams = parseInt(urlParams.get('score'), 10);
    setClockScore(scoreFromParams); 
    const storedSelectedWords = localStorage.getItem('selectedWords');
    const parsedSelectedWords = JSON.parse(storedSelectedWords);
    setParsedSelectedWords(parsedSelectedWords);
  }, []);

  useEffect(() => {
    if(speechRecognition.attempts === 0) {
      alert("Attempts exhausted going to result page")
      goToResultPage(speechRecognition.matchedWords.length);
    }
  }, [speechRecognition.attempts])

  
  

  useEffect(()=>{
    // All words matched
    if(speechRecognition.matchedWords != undefined && speechRecognition.matchedWords.length === 3){
      console.log(speechRecognition.matchedWords.length)
      alert("All words matched")
      setTotalPoints(speechRecognition.matchedWords.length)
      console.log(totalPoints)
       goToResultPage(speechRecognition.matchedWords.length)
    }
    else if(attempts==0){
      alert('Attempts exhausted. Moving to the next URL.');
      console.log(speechRecognition.matchedWords.length)
      setTotalPoints(speechRecognition.matchedWords.length);
      goToResultPage(speechRecognition.matchedWords.length);
      }
  },[speechRecognition.matchedWords])


  const goToResultPage = async (word_recall_score) =>{
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get("uid");
    console.log("Word recall",user_id);
    if (user_id) {
       const total_minicog_score = parseInt(word_recall_score) + parseInt(clock_score);
       console.log(total_minicog_score)
       const requestData = {
        score: {
          total: total_minicog_score,
          clock: parseInt(clock_score),
          recall: parseInt(speechRecognition.matchedWords.length),
        },
        uid: user_id,
      };
      console.log(requestData.score)
      await axios.post('https://us-central1-striped-rhino-401016.cloudfunctions.net/store-minicog-final-1', requestData )
      .then(response=>{
        console.log("Response from axios post", response)

      })
      .catch(error=>{
        console.log("Error from post request", error)
      });
      console.log('Data submitted successfully.');

      const newTabURL = `https://result.alzo.health/?uid=${user_id}`;
      window.location.href = newTabURL;
    } else {
      console.log("UID not found")
    }
  }
  
  const startRecording = async () => {
    if (instructionsBeingRead) {
      return;
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const newRecorder = new RecordRTC(stream, {
        type: 'audio',
        recorderType: RecordRTC.StereoAudioRecorder,
        mimeType: 'audio/wav',
      });
      newRecorder.startRecording();
      setRecording(true);
      setMediaStream(stream);
      setRecorder(newRecorder);
      setIsRecordingActive(true);

      // Start the timer
      if (!timer) {
        // startTimer();
      }

      // Start listening for speech recognition
      startSpeechRecognition();
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const startSpeechRecognition = () => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;

    recognition.onresult = (event) => {
      if (!isRecordingActive) {
        // Check if recording is active
        const recognizedText = event.results[event.results.length - 1][0].transcript;
        setRecognizedText(recognizedText);
        // Call checkRecordedWords after recognizedText is updated
        console.log(recognizedText);
      }
    };

    recognition.start();
  };

  const stopRecording = async () => {
    if (recording) {
      recorder.stopRecording(async () => {
        const blob = recorder.getBlob();
        // saveAs(blob, 'recorded-audio.mp3');
        mediaStream.getTracks().forEach((track) => track.stop());
        const audioFile = new File([blob], 'recorded-audio.mp3', { type: 'audio/mpeg' });

        setRecording(false);
        setMediaStream(null);
        setRecorder(null);
        setRecordingStopped(true);
        setRecordedAudio(audioFile);
        setIsRecordingActive(false);

        // Upload the audio file
        uploadAudioFile();

        // Check the recorded words
        // checkRecordedWords(recordedText);
      });
    }
  };

  const uploadAudioFile = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const user_id = urlParams.get('uid');

      const requestData = {
        userResponses: recordedAudio,
        uid: user_id.uid,
      };

      const response = await axios.post(
        'https://us-central1-striped-rhino-401016.cloudfunctions.net/store-mp3-2',
        requestData,
        {
          headers: {
            'Content-Type': 'audio/mpeg', // Make sure to set the correct content type
          },
        }
      );

      if (response.status === 200) {
        console.log('Audio data submitted successfully.');
      } else {
        console.error('Error submitting audio data:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const replayRecordedAudio = () => {
    if (recordedAudio) {
      // Check if recorded audio exists
      const audio = new Audio(URL.createObjectURL(recordedAudio));
      audio.play();
    }
  };

  const startTimer = () => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 59) {
          setMinutes((prevMinutes) => {
            if (prevMinutes === 59) {
              setHours((prevHours) => prevHours + 1);
              return 0;
            }
            return prevMinutes + 1;
          });
          return 0;
        }
        return prevSeconds + 1;
      });
    }, 1000);
  
    setTimer(timer);
  };
  const startTest = async () => {
    // Start the test and convert text to speech
    const textToSpeech =
    'Please listen carefully. I want you to repeat the three words that were provided to you in the first test. Click on the microphone button to start recording your answer. All the Best: ';
    // Convert text to speech
    await convertTextToSpeech(textToSpeech);
    const storedSelectedWords = localStorage.getItem('selectedWords');
    console.log("Selected words from start test:",storedSelectedWords);
    setSelectedWords(storedSelectedWords);
    // Now that speech is finished, set the state to show the microphone button
    setSpeechFinished(true);
    setInstructionsBeingRead(false);
    setTestStarted(true);
    startTimer();
  };

  return (
    <div>
      <Header />
<div className='bg-mainViolet p-0 m-0 w-full text-left py-4 relative'>
 <div className="flex flex-col md:flex-row items-start md:items-center justify-between px-10 md:px-20">
   <div className="mb-4 md:mb-0">
     <h3 className='text-5xl font-black p-2 pt-4 text-white'>Word Recall Test</h3>
     <h4 className='text-4xl font-semibold p-2 pt-4 text-white'>Step-by-Step Instructions for Part 3 of the Mini-Cog Assessment</h4>
   </div>
   <div className="md:ml-4 mt-4 md:mt-0">
     <div className="relative text-3xl text-white bg-buttonViolet p-4 rounded-lg shadow-md">
       <span className="font-bold">Time Elapsed:</span>
       {/* {String(this.state.hours).padStart(2, '0')}: */}
       <span className="mx-1">{String(minutes).padStart(2, '0')}</span>:
       <span className="mx-1">{String(seconds).padStart(2, '0')}</span>
     </div>
   </div>
 </div>
</div>



<ul className='bg-white text-3xl font-plain text-black mt-16 mb-16 ml-14 mr-14 text-left rounded-b-3xl rounded-t-3xl '>
 <li className='py-5 px-5 odd:bg-gray-200'>1. You were provided with a list of three unrelated words at the start of the MiniCog assessment.</li>
 <li className='py-5 px-5'>2. Your task is to recall those three words.</li>
 <li className='py-5 px-5 odd:bg-gray-200'>3. You should promptly recall them by clicking the start test button located below to hear the necessary instructions.</li>
 <li className='py-5 px-5 '>4. Once you have listened to the instructions, click on the microphone button to start recording.</li>

 <li className='py-5 px-5 odd:bg-gray-200'>5. You will have three opportunities to successfully recall these words during the assessment.</li>
      <div className='flex flex-col md:flex-row justify-center items-center'>
      {testStarted ? (
          <div>
            {recording ? (
              <div>
                <button onClick={speechRecognition.stopRecording} className='flex items-center justify-center'>
                  <img src={require('./mute_microphone.png')} alt='Stop Mic' className='w-20 h-20 ml-20 mt-3 mb-3 ' />
                </button>
                <p className='px-5 py-5 mt-5 mb-5'>Recording...</p>
              </div>
            ) : (
              (speechFinished || speechRecognition.inProgress) ? 
              (
                <div className='flex flex-col md:flex-col items-center'>
                  <button onClick={speechRecognition.startRecording} disabled={attempts === 0} className='flex items-center justify-center ' >
                    <img src={require('./microphone.png')} alt='Mic' className='w-20 h-20 ml-20 mt-3 mb-3 ' />
                  </button>
                  {recordingStopped ? <ReplayButton onClick={replayRecordedAudio} /> : null}
                  <div>
                    <div className='flex-row justify-center items-center'>
                      <p className='px-5 py-5 mt-3 mb-3'>Recorded Text: {speechRecognition.lastestThreeWords}</p>
                      <p className='px-5 py-5 mt-3 mb-3'>Current Text: {speechRecognition.current}</p>
                      <p className='px-5 py-5 mt-3 mb-3'>Attempts Left: {speechRecognition.attempts}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <p className='mt-5 mb-5'>Reading instructions...</p>
              )
            )}
          </div>
        ) : (
          <>
          <button
            className='start-button ml-6 mt-6 mb-6 pd-5 '
            onClick={startTest}
            disabled={testStarted}
            style={{ fontSize: '30px' }}
          >
            <b>Start Test</b>
          </button>
          </>
          
        )}
         
      </div>
    </ul>
    <Footer />

  </div>
  );
};

function ReplayButton({ onClick }) {
  return (
    <button className='replay-button' onClick={onClick}>
      Replay Audio
    </button>
  );
}

export default TestThree;
