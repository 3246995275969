import React from 'react';
// import ReactDOM from 'react-dom/client';
import './index.css';
import {createRoot} from 'react-dom/client';

import Pages from './pages';
const rootElement = 
document.getElementById('root');
const root = 
createRoot(rootElement);

root.render(
    <Pages />
);


