import React from 'react'
import { useState } from 'react';
import ContactPopup from './ContactPopup'
import { Link } from 'react-router-dom';

export default function Footer({homePath, privacyPolicyPath, termsOfServicePath}) {

  const [isContactPopupOpen, setContactPopupOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  const openContactPopup = () => {
    setContactPopupOpen(true);
  };

  const closeContactPopup = () => {
    setContactPopupOpen(false);
  };

  const handleContactLinkClick = (e) => {
    e.preventDefault();
    openContactPopup();
  };


  return (
    <div>
      
<footer className="bg-mainViolet">
    <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
              <a href="https://alzohealth.com/" className="flex items-center">
                  <img src="https://storage.googleapis.com/alzohealth-public-assets/AlzoHealth_logo_2024%20(1).png" className="h-24  mr-3" alt="Alzohealth Logo" />
                  <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">Alzohealth</span>
              </a>
          </div>
          
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
              <h2 className="mb-6 text-sm font-semibold uppercase text-white">Resources</h2>
                  <ul className="text-white font-medium">
                      <li className="mb-4">
                          <a href={homePath} className="hover:underline">AlzoHealth</a>
                      </li>
                      <li className="mb-4">
                      
                          <a href="#" className="hover:underline" onClick={handleContactLinkClick}>Contact Us</a>
                          <ContactPopup isOpen={isContactPopupOpen} onClose={closeContactPopup} />
                      </li>
                  </ul>
              </div>
              
              <div>
                  <h2 className="mb-6 text-sm font-semibold  uppercase text-white">Follow us</h2>
                  <ul className="text-white font-medium">
                      <li className="mb-4">
                          <a href="https://linkedin.com/company/alzo-health" className="hover:underline " target='new'>LinkedIn</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6 text-sm font-semibold uppercase text-white">Legal</h2>
                  <ul className="text-white font-medium">
                      <li className="mb-4">
                        <Link to = {termsOfServicePath}>
                            <button className="hover:underline">Terms of Service</button>
                        </Link>   
                      </li>
                      <li>
                          <Link to = {privacyPolicyPath}>
                          <button className="hover:underline">Privacy Policy</button>
                          </Link>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <hr className="my-6 border-lightViolet sm:mx-auto  lg:my-8" />

            <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-white sm:text-center">
      © {currentYear} <a href="https://flowbite.com/" className="hover:underline">AlzoHealth</a>. All Rights Reserved.
    </span>
                <div className="flex mt-4 space-x-5 sm:justify-center sm:mt-0">

                <a href="https://linkedin.com/company/alzo-health" className="text-white hover:text-lightViolet">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759" fill="white"/>
                    </svg>
                </a>
          </div>
      </div>
    </div>
</footer>

    </div>
  )
}
