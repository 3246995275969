import React, { useState } from 'react';
import { Link } from 'react-router-dom';
export default function Header() {
  const [showLogin, setShowLogin] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = () => {
    // Simulating a successful login
    setLoggedIn(true);
    setShowLogin(false);
  }

  const handleLogout = () => {
    // Log out the user
    setLoggedIn(false);
  }

  const handleCloseLoginPopup = () => {
    setShowLogin(false);
  }

  const openLoginPopup = () => {
    setShowLogin(true);
  }

  return (
    <nav className="bg-lightViolet flex flex-wrap justify-between items-center ">
      <div className="flex items-center space-x-4">
        <img src="https://storage.googleapis.com/alzohealth-public-assets/AlzoHealth_logo_2024%20(1).png" alt="AlzoHealth Logo" className="mx-4 h-28" />
        <button className="text-black hover:text-gray-300 text-xl mx-4 lg:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        <div className="hidden lg:flex space-x-4">
          <Link to="https://alzo.health/">
            <button className="text-black hover:text-buttonViolet text-lg mx-4">Home</button>
          </Link>

          <Link to="https://alzo.health/partners">
            <button className="text-black hover:text-buttonViolet text-lg mx-4">Partners</button>
          </Link>
          <Link to="https://alzo.health/aboutUs">
            <button className="text-black hover:text-buttonViolet text-lg mx-4">About Us</button>
          </Link>
        </div>
      </div>
    </nav>
  );
}
