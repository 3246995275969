import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import TestOne from './components/word-registration';
import TestThree from './components/word-recall';
import TestTwo from './App_Clock';
export default function Pages() {
  return (
      <BrowserRouter>
        <Routes>
            <Route path='/' element={<TestOne />}></Route>
            <Route path='/clock-drawing' element={<TestTwo />}></Route>
            <Route path='/word-recall' element={<TestThree />}></Route>
        </Routes>
      </BrowserRouter>
  )
}