import axios from 'axios';

const convertTextToSpeech = async (text, mute = false) => {
  const apiKey = process.env.REACT_APP_TEXT_TO_SPEECH_API_KEY;
  try {
    const requestData = {
      input: {
        text: text,
      },
      voice: {
        languageCode: 'en-US',
        name: 'en-US-Wavenet-D',
        ssmlGender: 'NEUTRAL',
      },
      audioConfig: {
        audioEncoding: 'LINEAR16',
      },
    };

    const response = await axios.post(
      `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`,
      requestData
    );

    const audioContent = response.data.audioContent;
    const audioBlob = base64toBlob(audioContent, 'audio/wav');
    const audio = new Audio(URL.createObjectURL(audioBlob));

    if (!mute) {
      audio.play();
    }

    return audio; // Return the audio element for further control if needed
  } catch (error) {
    console.error('Error making API request:', error);
    return null; // Indicates failure in speech synthesis
  }
};

const base64toBlob = (base64Data, contentType) => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export default convertTextToSpeech;
