import React, { useState, useEffect } from 'react';
import './App_Clock.css';
import Header from './components/header';
import Clock from './components/ClockTest/Clock';
import Footer from './components/Footer';
import goToPage3 from './utils/goToWordRecall';

function TestTwo() {
  const [gameOver, setGameOver] = useState(false);
  const [user_id, setUserID] = useState('');
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get("uid");
    setUserID(user_id);
  }, [])

  const startTimer = () => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          if (minutes === 0) {
            clearInterval(timer);
            setGameOver(true);
            if (user_id) {
              // Redirect to a new URL with the 'uid' parameter
              window.location.href = `/word-recall?uid=${user_id}&score=${0}`;
            } else {
              // Handle the case where 'uid' is not present in the query string
              console.log("UID not found")
            }
            return 0;
          }
          setMinutes((prevMinutes) => {
            if (prevMinutes === 0) {
              clearInterval(timer);
              setGameOver(true);
              if (user_id) {
                // Redirect to a new URL with the 'uid' parameter
                window.location.href = `/word-recall?uid=${user_id}&score=${0}`;
              } else {
                // Handle the case where 'uid' is not present in the query string
                console.log("UID not found")
              }
              return 0;
            }
            return prevMinutes - 1;
          });
          if(minutes!==0){
            return 59;
          } else{
            return 0;
          }
        }
        return prevSeconds - 1;
      });
    }, 1000);
  
    setTimer(timer);
  };
  
  
  

  useEffect(() => {
    // Add an event listener to the document to start the timer when the user clicks
    document.addEventListener('click', startTimer);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', startTimer);
      // Clear the timer when the component unmounts
      clearInterval(timer);
    };
  }, [timer]); 

  return (
    <div>
      {/* Navbar component */}
      <Header
        homePath="https://alzo.health/" 
        partnersPath="https://alzo.health/partners" 
        aboutUsPath="https://alzo.health/aboutUs" 
      />
      {/* Instructions on screen */}
      <div className='bg-mainViolet p-0 m-0 w-full text-left py-4 relative'>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between px-10 md:px-20">
          <div className="mb-4 md:mb-0">
            <h3 className='text-5xl font-black p-2 pt-4 text-white'>Clock Drawing Test</h3>
            <h4 className='text-4xl font-black p-2 pt-4 text-white'>Step-by-Step Instructions for Part 2 of the Mini-Cog Assessment</h4>
          </div>
          <div className="md:ml-4 mt-4 md:mt-0">
            <div className="relative text-3xl text-white bg-buttonViolet p-4 rounded-lg shadow-md">
              <span className="font-bold">Time Left:</span>
              <span className="mx-1">{String(minutes).padStart(2, '0')}</span>:
              <span className="mx-1">{String(seconds).padStart(2, '0')}</span>
            </div>
          </div>
        </div>
      </div>
      <Clock />   
      <Footer /> 
      {gameOver && <div>Game Over!</div>}
    </div>
  );
}

export default TestTwo;
