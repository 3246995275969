import React, { useState, useEffect } from 'react';
import RecordRTC from 'recordrtc';
import './word-registration.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from './header';
import Footer from './Footer';
import convertTextToSpeech from '../utils/ConvertTextToSpeech';
import useSpeechRecognition from '../utils/useSpeechRecognition';
const TestOne = () => {
  const [recording, setRecording] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [totalPoints, setTotalPoints] = useState(0);
  const [testStarted, setTestStarted] = useState(false);
  const [speechFinished, setSpeechFinished] = useState(false);
  const [recordedText, setRecordedText] = useState('');
  const [recordingStopped, setRecordingStopped] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [selectedWords, setSelectedWords] = useState();
  const [isRecordingActive, setIsRecordingActive] = useState(false);
  const [instructionsBeingRead, setInstructionsBeingRead] = useState(false);
  const speechRecognition = useSpeechRecognition(selectedWords);
  const [timer, setTimer] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const user_id = (urlParams.get("uid"));
        console.log(urlParams,user_id);
        console.log("JSON vala uid",JSON.stringify(user_id))

        console.log("Test started: ", testStarted);
        console.log("Recording: ", recording);
        const response = await axios.get(
          'https://us-central1-striped-rhino-401016.cloudfunctions.net/read-words'
        );
        
        if (response.status === 200 && response.data && response.data) {
          const selectedWords = response.data;
          console.log("From use effect", selectedWords);
          console.log("Type of selected words is: ", typeof(selectedWords));
          setSelectedWords(selectedWords);
          localStorage.setItem('selectedWords', JSON.stringify(selectedWords));
          // setTestStarted(true);
          setInstructionsBeingRead(true);
        }
      } catch (error) {
        console.log("Error fetching words:", error);
      }
    };
  
    fetchData();
  }, []);

  useEffect(()=>{
    console.log("MATCHED WORDS: ",speechRecognition.matchedWords)
    console.log(speechRecognition.matchedWords.length)

    if(speechRecognition.matchedWords != undefined && speechRecognition.matchedWords.length === 3){
      alert("All words matched")
      goToPage2(3)
      console.log("All words matched")
    }
  },[speechRecognition.matchedWords])



  function goToPage2(score) {
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get("uid");
    console.log(user_id);
    console.log("JSON vala uid",JSON.stringify(user_id.uid))
    if (user_id) {
      // Redirect to a new URL with the 'uid' parameter
      window.location.href = `/clock-drawing?uid=${user_id}&score=${score}`;
    } else {
      // Handle the case where 'uid' is not present in the query string
      console.log("UID not found")
    }
  }

  const startRecording = async () => {
    if (instructionsBeingRead) {
      return;
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const newRecorder = new RecordRTC(stream, {
        type: 'audio',
        recorderType: RecordRTC.StereoAudioRecorder,
        mimeType: 'audio/wav',
      });
      newRecorder.startRecording();
      // setRecording(true);
      setMediaStream(stream);
      setRecorder(newRecorder);
      setIsRecordingActive(true);

      // Start the timer
      if (!timer) {
        startTimer();
      }

      // Start listening for speech recognition
      startSpeechRecognition();
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const startSpeechRecognition = () => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;

    recognition.onresult = (event) => {
      if (!isRecordingActive) {
        // Check if recording is active
        const recognizedText = event.results[event.results.length - 1][0].transcript;
        setRecordedText(recognizedText);
        // Call checkRecordedWords after recognizedText is updated
        console.log(recognizedText);
      }
    };

    recognition.start();
  };

  const stopRecording = async () => {
    if (recording) {
      recorder.stopRecording(async () => {
        const blob = recorder.getBlob();
        // saveAs(blob, 'recorded-audio.mp3');
        mediaStream.getTracks().forEach((track) => track.stop());
        const audioFile = new File([blob], 'recorded-audio.mp3', { type: 'audio/mpeg' });

        setRecording(false);
        setMediaStream(null);
        setRecorder(null);
        setRecordingStopped(true);
        setRecordedAudio(audioFile);
        setIsRecordingActive(false);

        // Upload the audio file
        uploadAudioFile();

        // Check the recorded words
        // checkRecordedWords(recordedText);
      });
    }
  };

  const uploadAudioFile = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const user_id = urlParams.get('uid');

      const requestData = {
        userResponses: recordedAudio,
        uid: user_id.uid,
      };

      const response = await axios.post(
        'https://us-central1-striped-rhino-401016.cloudfunctions.net/store-mp3-2',
        requestData,
        {
          headers: {
            'Content-Type': 'audio/mpeg', 
          },
        }
      );

      if (response.status === 200) {
        console.log('Audio data submitted successfully.');
      } else {
        console.error('Error submitting audio data:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const replayRecordedAudio = () => {
    if (recordedAudio) {
      // Check if recorded audio exists
      const audio = new Audio(URL.createObjectURL(recordedAudio));
      audio.play();
    }
  };

  const startTimer = () => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 59) {
          setMinutes((prevMinutes) => {
            if (prevMinutes === 59) {
              setHours((prevHours) => prevHours + 1);
              return 0;
            }
            return prevMinutes + 1;
          });
          return 0;
        }
        return prevSeconds + 1;
      });
    }, 1000);
  
    setTimer(timer);
  };
  
  const startTest = async () => {
        // Display "Reading instructions..." immediately
        // forceUpdate();
        console.log("Starting test...");

        // Start the test and convert text to speech
        const textToSpeech =
          'Please listen carefully. I want you to remember these three words, ' +
          selectedWords.join(', ') +
          ' , Click on the microphone button to start recording your answer.';
        // Convert text to speech
        await convertTextToSpeech(textToSpeech);
        // Now that speech is finished, set the state to show the microphone button
        setSpeechFinished(true);
        setInstructionsBeingRead(false);
        setTestStarted(true);
        startTimer();
        console.log(selectedWords);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const user_id =(urlParams.get('uid'));
  console.log(user_id)
  console.log("JSON vala uid",JSON.stringify(user_id))


  return (
    <div>
      <Header />
<div className='bg-mainViolet p-0 m-0 w-full text-left py-4 relative'>
 <div className="flex flex-col md:flex-row items-start md:items-center justify-between px-10 md:px-20">
   <div className="mb-4 md:mb-0">
     <h3 className='text-5xl font-black p-2 pt-4 text-white'>Word Registration Test</h3>
     <h4 className='text-4xl font-semibold p-2 pt-4 text-white'>Step-by-Step Instructions for Part 1 of the Mini-Cog Assessment</h4>
   </div>
   <div className="md:ml-4 mt-4 md:mt-0">
     <div className="relative text-3xl text-white bg-buttonViolet p-4 rounded-lg shadow-md">
       <span className="font-bold">Time Elapsed:</span>
       {/* {String(this.state.hours).padStart(2, '0')}: */}
       <span className="mx-1">{String(minutes).padStart(2, '0')}</span>:
       <span className="mx-1">{String(seconds).padStart(2, '0')}</span>
     </div>
   </div>
 </div>
</div>



<ul className='bg-white text-3xl font-plain text-black mt-16 mb-16 ml-14 mr-14 text-left rounded-b-3xl rounded-t-3xl '>
<li className=' odd:bg-gray-200 py-5 px-5'>1. You will be provided with a list of three unrelated words at the start of the assessment.</li>
          <li className=' even:bg-white-200 py-5 px-5'>2. Your immediate task is to carefully listen to these words.</li>
          <li className=' odd:bg-gray-200 py-5 px-5'>3. After hearing the words, you should promptly recall them by clicking the start test button located below.</li>
          <li className='even:bg-white-200 py-5 px-5'>4. It's important to make an effort to remember these words, as you will be asked to recall them again later in the assessment.</li>
          <li className=' odd:bg-gray-200 py-5 px-5'>5. You will have three opportunities to successfully recall these words during the assessment.</li>

        <div className='flex flex-col md:flex-row justify-center items-center'>
        {testStarted ? (
            <div>
              {recording ? (
                <div>
                  <button onClick={speechRecognition.stopRecording} className='flex items-center justify-center'>
                    <img src={require('./mute_microphone.png')} alt='Stop Mic' className='w-20 h-20 ml-20 mt-3 mb-3 font-bold' />
                  </button>
                  <p className='px-5 py-5 mt-5 mb-5'>Recording...</p>
                </div>
              ) : (
                (speechFinished || speechRecognition.inProgress) ? (
                  <div>
                    <button
                      onClick={speechRecognition.startRecording}
                      className={`flex items-center justify-center ${instructionsBeingRead ? 'cursor-not-allowed opacity-50' : ''}`}
                    >
                      <img src={require('./microphone.png')} alt='Mic' className='w-20 h-20 ml-20 mt-3 mb-3' />
                    </button>
                    {recordingStopped ? <ReplayButton onClick={replayRecordedAudio} /> : null}
                    <div>
                      <p className='px-5 py-5 mt-3 mb-3'>Recorded Text: {speechRecognition.lastestThreeWords}</p>
                      <p className='px-5 py-5 mt-3 mb-3'>Current Text: {speechRecognition.current}</p>
                      <p className='px-5 py-5 mt-3 mb-3'>Attempts Left: {speechRecognition.attempts}</p>
                    </div>
                  </div>
                ) : (
                  <p className='mt-5 mb-5'>Reading instructions...</p>
                )
              )}
            </div>
          ) : (
            <>
            <button
              className='start-button ml-6 mt-6 mb-6 pd-5 '
              onClick={startTest}
              disabled={testStarted}
              style={{ fontSize: '30px' }}
            >
              <b>Start Test</b>
            </button>
            </>
            
          )}
          {totalPoints === 3 || speechRecognition.attempts === 0 ? (
            <Link to={`/clock-drawing?uid=${user_id.uid}`}>
              <button className='next-test-button'>Next Test</button>
            </Link>
          ) : null}
        </div>
      </ul>
      <Footer />

    </div>
  );
};



function ReplayButton({ onClick }) {
  return <button className='replay-button' onClick={onClick}>Replay Audio</button>;
}

export default TestOne;

