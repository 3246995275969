import React from 'react';

const NumberPanel = () => {
  const handleDragStart = (event, number) => {
    event.dataTransfer.setData('text/plain', number);
  };

  return (
    <div className="grid grid-cols-6 gap-5 bg-white p-4 w-auto rounded-3xl">
  {[...Array(12).keys()].map((number) => (
    <div
      key={number}
      className="draggable text-2xl hover:text-3xl font-bold mx-auto w-12 h-12 flex items-center justify-center"
      draggable="true"
      onDragStart={(e) => handleDragStart(e, `${number + 1}`)}
    >
      {number + 1}
    </div>
  ))}
</div>

  );
};

export default NumberPanel;
