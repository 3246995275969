import React, { useEffect, useState } from 'react';
import NumberPanel from '../NumberPanel';
import convertTextToSpeech from '../../utils/ConvertTextToSpeech';
import InstructionPopup from './InstructionPopup';

function Clock() {
  const [hourHandRotation, setHourHandRotation] = useState(90);
  const [minuteHandRotation, setMinuteHandRotation] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [selectedHand, setSelectedHand] = useState(null);
  const [inputFieldValues, setInputFieldValues] = useState([]);
  const [canvasWidth, setCanvasWidth] = useState(700);
  const [canvasHeight, setCanvasHeight] = useState(700);
  const [innerCircleDimension, setInnerCircleDimension] = useState(32);
  const [innerCircleFontSize, setInnerCircleFontSize] = useState(22);
  const [clockDivisionLength, setClockDivisionLength] = useState(10);
  const [normalTextFontSize, setNormalTextFontSize] = useState('text-2xl');
  const [headingTextFontSize, setHeadingTextFontSize] = useState('text-3xl') ;
  const [instructionsBeingRead, setInstructionsBeingRead] = useState(false);
  const [instructionRead, setInstructionRead] = useState(false);

  const [instructionPopup, setInstrutionPopup] = useState(true)


  useEffect(() => {
    const handleClick = async () => {
      if (!instructionRead) {
        const text = "Please draw a clock, placing all the numbers in their respective positions. Once you've completed that, set the clock hands to 10 past 11. If the clock isn't finished within three minutes, we'll move on to Step 3.";
        await convertTextToSpeech(text);
        setInstructionRead(true);
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    }
  }, [instructionRead]);
  useEffect(() => {
    const handleMouseMove = (event) => {
      const canvas = document.getElementById("outer-clock-canvas");
      const rect = canvas.getBoundingClientRect();
      const x_center_of_clock = rect.left + canvas.width / 2;
      const y_center_of_clock = rect.top + canvas.height / 2;

      const angle = Math.atan2(
        event.clientY - y_center_of_clock,
        event.clientX - x_center_of_clock
      );

      if (dragging) {
        if (selectedHand === 1) {
          setHourHandRotation(angle);
        } else if (selectedHand === 2) {
          setMinuteHandRotation(angle);
        }
      }
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [dragging, selectedHand]);
  // Update the clock based on the changes made by user
  useEffect(() => {
      const canvas = document.getElementById("outer-clock-canvas");
      const ctx = canvas.getContext("2d");
      const x_center_of_clock = canvas.width / 2;
      const y_center_of_clock = canvas.height / 2;
      const outer_clock_radius = canvas.width / 2;
      ctx.fillStyle = "#FFFFFF";
      ctx.strokeStyle = "#FFFFFF";
      ctx.lineWidth = 2;
      // Clock canvas
      const outer_clock_circle = new Path2D();
      outer_clock_circle.arc(x_center_of_clock, y_center_of_clock, outer_clock_radius, 0, 2 * Math.PI);
      ctx.stroke(outer_clock_circle);
      ctx.fill(outer_clock_circle);
      // Minute division
      drawMinuteDivisions()
      drawClockHands(hourHandRotation, minuteHandRotation);
      // Adding event functions
      canvas.addEventListener("mousedown", handleMouseDown);
      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseup", handleMouseUp);
      // Only able to drag one as we are using getElementById
      const draggable = document.querySelectorAll(".draggable");
      const droppables = document.querySelectorAll(".droppable");
      draggable.forEach((number) => {
        number.addEventListener("dragstart", (event) => {
          event.dataTransfer.setData("text/plain", event.target.textContent);
        });
      });
    // Add dragover and drop event listeners to the input fields.
    droppables.forEach((inputField) => {
      inputField.addEventListener("dragover", (event) => {
        event.preventDefault();
      });
      inputField.addEventListener("drop", (event) => {
        event.preventDefault();
        const data = event.dataTransfer.getData("text/plain");
        inputField.value = data;
      });
    });
     // Periodically run checkUserDrawing method every 3 seconds
    const intervalId = setInterval(() => {
      checkUserDrawing();
    }, 3000);

      return () => {
        // Cleanup event listeners on component unmount
        canvas.removeEventListener("mousedown", handleMouseDown);
        canvas.removeEventListener("mousemove", handleMouseMove);
        canvas.removeEventListener("mouseup", handleMouseUp);
        clearInterval(intervalId);
      };
  }, [hourHandRotation, minuteHandRotation, dragging, canvasHeight, canvasWidth]);
  // To update the clock size based on screen size
  useEffect(() => {
    const canvas = document.getElementById("outer-clock-canvas");
      const x_center_of_clock = canvas.width / 2;
      const y_center_of_clock = canvas.height / 2;
      const outer_clock_radius = canvas.width / 2;
      const padding = 30;
      // Create an array to store input field values
      const initialValues = [];
      // Function to handle input field value changes and update the state
      const handleInputChange = (event, index) => {
        const newInputFieldValues = [...inputFieldValues];
        newInputFieldValues[index] = event.target.value;
        setInputFieldValues(newInputFieldValues);
      };
      // Check if input fields already exist
      const inputFieldsExist = document.querySelectorAll('.input-field').length > 0;
      // Delete if it exist - To make it responsive based on the screen size
      if(inputFieldsExist){
        const existingInputFields = document.querySelectorAll('.input-field');
        existingInputFields.forEach(inputField => {
          inputField.remove();
        });
      }
      // Creating an input field at each hour based on screen size so that it overlaps the canvas
      for (let time = 1; time <= 12; time++) {
        const angle = (time / 12) * (2 * Math.PI);
        const distanceFromCenter = outer_clock_radius - padding;
        const x = x_center_of_clock + distanceFromCenter * Math.cos(angle);
        const y = y_center_of_clock + distanceFromCenter * Math.sin(angle);
        // Creating a new input field for clock time
        const inputField = document.createElement("input");
        inputField.addEventListener('input', (event) => {
          const index = parseInt(event.target.value) - 1; 
            handleInputChange(event, index);
        });
        inputField.className = "input-field droppable";
        inputField.style.position = "absolute";
        inputField.style.width = `${innerCircleDimension}px`;
        inputField.style.height = `${innerCircleDimension}px`;
        inputField.style.fontWeight = "bold";
        inputField.style.fontSize = `${innerCircleFontSize}px`;
        inputField.style.textAlign = "center";
        inputField.style.borderRadius = "50%";
        inputField.style.left = x - 12 + "px";
        inputField.style.top = y - 12 + "px";
        inputField.style.backgroundColor = "#9690EA";
        inputField.readOnly = false;
        document.getElementById("input-fields-container").appendChild(inputField);
        initialValues.push('');
      }
      console.log("Input fields added");
      setInputFieldValues(initialValues);
  }, [canvasHeight, canvasWidth])
  // To make the clock component reponsive and update it
  useEffect(() => {
    const lgMediaQuery = window.matchMedia('(min-width: 1024px)');
    const mdMediaQuery = window.matchMedia('(min-width: 768px)');
    const smMediaQuery = window.matchMedia('(min-width: 640px)');
    // Index-0: innerCircleDimension Index-1: InnerCircleFontSize Index-2: clockDivisionLength
    const sizeArray = [
      [32, 22, 10],
      [32, 22, 10],
      [26, 18, 10],
      [24, 16, 8]
    ];
    const handleScreenSizeChange = () => {
      if (lgMediaQuery.matches) {
        setCanvasWidth(600); 
        setCanvasHeight(600); 
        setInnerCircleDimension(sizeArray[0][0])
        setInnerCircleFontSize(sizeArray[0][1])
        setClockDivisionLength(sizeArray[0][2])
      } else if (mdMediaQuery.matches) {
        setCanvasWidth(500); 
        setCanvasHeight(500);
        setInnerCircleDimension(sizeArray[1][0])
        setInnerCircleFontSize(sizeArray[1][1])
        setClockDivisionLength(sizeArray[1][2])
      } else if (smMediaQuery.matches) {
        setCanvasWidth(400);
        setCanvasHeight(400);
        setInnerCircleDimension(sizeArray[2][0])
        setInnerCircleFontSize(sizeArray[2][1])
        setClockDivisionLength(sizeArray[2][2])
      } else {
        setCanvasWidth(350);
        setCanvasHeight(350);
        setInnerCircleDimension(sizeArray[3][0])
        setInnerCircleFontSize(sizeArray[3][1])
        setClockDivisionLength(sizeArray[3][2])
      }
    };
    handleScreenSizeChange();
    lgMediaQuery.addEventListener('change', handleScreenSizeChange);
    mdMediaQuery.addEventListener('change', handleScreenSizeChange);
    smMediaQuery.addEventListener('change', handleScreenSizeChange);
    return () => {
      lgMediaQuery.removeEventListener('change', handleScreenSizeChange);
      mdMediaQuery.removeEventListener('change', handleScreenSizeChange);
      smMediaQuery.removeEventListener('change', handleScreenSizeChange);
    };
  }, []);
  // To make the text on the screen responsive based on custom screen size
  useEffect(() => {
    const handleResize = () => {
      // Adjust font size based on screen width
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setNormalTextFontSize('text-lg'); 
        setHeadingTextFontSize('text-xl')
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setNormalTextFontSize('text-xl'); 
        setHeadingTextFontSize('text-2xl')
      } else {
        setNormalTextFontSize('text-2xl');
        setHeadingTextFontSize('text-3xl')
      }
    };
    // Initial size
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    // Method to goto next assessment
    function goToPage3() {
      const urlParams = new URLSearchParams(window.location.search);
      const user_id = urlParams.get("uid");
      console.log(user_id);
      if (user_id) {
        // Redirect to a new URL with the 'uid' parameter
        window.location.href = `/word-recall?uid=${user_id}&score=${2}`;
      } else {
        // Handle the case where 'uid' is not present in the query string
        console.log("UID not found")
      }
    }
    // Update the selected hand
    const handleCheckboxChange = (hand) => {
      setSelectedHand(hand);
    };
  // Function to draw clock hands
  function drawClockHands() {
    // Getting reference
    const canvas = document.getElementById("outer-clock-canvas");
    const ctx = canvas.getContext("2d");
    const x_center_of_clock = canvas.width / 2;
    const y_center_of_clock = canvas.height / 2;
    const outer_clock_radius = canvas.width / 2;
    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // Outline of canvas
    ctx.beginPath();
    ctx.arc(x_center_of_clock, y_center_of_clock, outer_clock_radius, 0, 2 * Math.PI);
    ctx.fillStyle = "#FFFFFF";
    ctx.strokeStyle = "#FFFFFF";
    ctx.lineWidth = 2;
    ctx.stroke();
    ctx.fill();
    // Draw minute divisions
    drawMinuteDivisions();
    // Draw hour hand
    ctx.beginPath();
    ctx.moveTo(x_center_of_clock, y_center_of_clock);
    const hourHandLength = outer_clock_radius * 0.6;
    const hourX = x_center_of_clock + hourHandLength * Math.cos(hourHandRotation);
    const hourY = y_center_of_clock + hourHandLength * Math.sin(hourHandRotation);
    ctx.lineTo(hourX, hourY);
    ctx.lineWidth = 12;
    ctx.strokeStyle = "#0F2C59";
    ctx.lineCap = "round"; 
    ctx.stroke();

    // Draw minute hand
    ctx.beginPath();
    ctx.moveTo(x_center_of_clock, y_center_of_clock);
    const minuteHandLength = outer_clock_radius * 0.8;
    const minuteX = x_center_of_clock + minuteHandLength * Math.cos(minuteHandRotation);
    const minuteY = y_center_of_clock + minuteHandLength * Math.sin(minuteHandRotation);
    ctx.lineTo(minuteX, minuteY);
    ctx.lineWidth = 12;
    ctx.strokeStyle = "red";
    ctx.lineCap = "round"; 
    ctx.stroke();
  }
  // Function to draw minute divisions
  function drawMinuteDivisions() {
    // Getting reference
    const canvas = document.getElementById("outer-clock-canvas");
    const ctx = canvas.getContext("2d");
    const x_center_of_clock = canvas.width / 2;
    const y_center_of_clock = canvas.height / 2;
    const outer_clock_radius = canvas.width / 2;
    const minuteDivisions = 60;
    for (let division = 1; division <= minuteDivisions; division++) {
      const angle = (division / minuteDivisions) * (2 * Math.PI);
      const x1 = x_center_of_clock + (outer_clock_radius - `${clockDivisionLength}`) * Math.cos(angle);
      const y1 = y_center_of_clock + (outer_clock_radius - `${clockDivisionLength}`) * Math.sin(angle);
      const x2 = x_center_of_clock + outer_clock_radius * Math.cos(angle);
      const y2 = y_center_of_clock + outer_clock_radius * Math.sin(angle);
      // Draw the minute division
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.strokeStyle = "#0F2C59";
      ctx.lineWidth = 2;
      ctx.stroke();
    }
  }
  function handleMouseDown() {
    const updatedInputFieldValues = [];
    const inputFields = document.querySelectorAll('.input-field');
    const values = [];
    inputFields.forEach((inputField, index) => {
      updatedInputFieldValues[index] = inputField.value;
      console.log(`Value in input field ${index + 1}:`, inputField.value);
      values.push(inputField.value);
    });
    console.log(values);
    console.log(inputFields)
    console.log("Click in canvas")
    setInputFieldValues(updatedInputFieldValues);
    setDragging(true);
  }
  function handleMouseMove(event) { 
    const canvas = document.getElementById("outer-clock-canvas");
    const x_center_of_clock = canvas.width / 2;
    const y_center_of_clock = canvas.height / 2;
    if (dragging) {
        const angle = Math.atan2(
          event.clientY - y_center_of_clock,
          event.clientX - x_center_of_clock
        );
        
        if(selectedHand === 1){
            setHourHandRotation(angle);
            // -2.0533096218173736
            console.log("Hour hand angle: ", angle);
        }
        if(selectedHand === 2){
            setMinuteHandRotation(angle);
            // -0.5227890853804023
            console.log("Minute hand angle: ", angle);
        }   
    }
  }
  function checkUserDrawing(){
    const expected_time = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];
    const expected_hour_angle = parseFloat(-2.0533096218173736.toFixed(3));
    const expected_minute_angle = parseFloat(-0.5227890853804023.toFixed(3));
    const currentHourAngle = parseFloat(hourHandRotation.toFixed(3));
    const currentMinuteAngle = parseFloat(minuteHandRotation.toFixed(3));
    const current_clock_values = [];
    // Get clock time
    const inputFields = document.querySelectorAll('.input-field');
    inputFields.forEach((inputField, index) => {
      current_clock_values.push(parseInt(inputField.value));
    });
    let flag = true;
      for (let i = 0; i < expected_time.length; i++) {
        if (expected_time[i] !== current_clock_values[i]) flag = false;
      }
      // Checking whether the current location of hour and minute hand are in a well-defined range
      if (
        !(
          currentHourAngle >= expected_hour_angle - 0.1 &&
          currentHourAngle <= expected_hour_angle + 0.1 &&
          currentMinuteAngle >= expected_minute_angle - 0.1 &&
          currentMinuteAngle <= expected_minute_angle + 0.1
        )
      ) {
        flag = false;
      }
      if (flag) {
        localStorage.setItem('flag', flag);
        window.alert("The clock drawing is accurate!");
        goToPage3();
      } else {
        console.log("Not as expected")
      }
  }

  function handleMouseUp() {
    setDragging(false);
  }
  return (
    <div>
    {/* {instructionPopup && <InstructionPopup/>} */}
    <div className='mx-auto flex items-center justify-center my-12'>
      <div className="bg-testBackground p-8 rounded-3xl w-10/12 flex flex-col lg:flex-row md:flex-row justify-evenly items-center lg:space-y-4 md:space-y-4 space-y-8">
        {/* Clock content */}
        <div className='relative mx-4'>
          <canvas
            id="outer-clock-canvas"
            className="p-0 m-0  top-0 left-0" 
            width={canvasWidth}
            height={canvasHeight}
          ></canvas>
          <div id="input-fields-container" className="top-0 left-0"></div>
        </div>
        {/* Buttons for selecting hands */}
        <div className='flex flex-col justify-evenly mx-4'>
              {/* Instruction */}
              <div>
                <p className={`text-left font-bold ml-6 mb-2 ${headingTextFontSize}`}>Instructions:-</p>
                <ul className={`text-left font-normal  ${normalTextFontSize}`}>
                  <li className='lg:py-2 md:py-2 sm:py-2'>1. Drag and drop or write the numbers 1 to 12 in their proper positions around the edge of the clock which you can see on the screen.</li>
                  <li className='lg:py-2 md:py-2 sm:py-2'>2. Once you have all the numbers in place, adjust the clock hand to 10 past 11.</li>
                  <li className='lg:py-2 md:py-2 sm:py-2'>3. You can adjust the clock hands by clicking and moving them on the screen.</li>
                </ul>
              </div>
              {/* Select hand option */}
              <div className='text-left'>
                <p className={`font-bold ml-6 my-4 ${headingTextFontSize}`}>Select the hand which you want to move:-</p>
                <div className={`flex flex-row ml-6 justify-start font-normal ${normalTextFontSize}`}>
                  <label className="flex items-center mb-2">
                      <input
                      type="checkbox"
                      className="form-checkbox h-6 w-6 rounded-full mr-2"
                      onChange={() => handleCheckboxChange(1)}
                      checked={selectedHand === 1}
                      />
                      Blue hand
                  </label>
                  <label className="flex items-center ml-16">
                      <input
                      type="checkbox"
                      className="form-checkbox h-6 w-6 rounded-full mr-2"
                      onChange={() => handleCheckboxChange(2)}
                      checked={selectedHand === 2}
                      />
                      Red hand
                  </label>
                </div>
              </div>
          {/* Number panel */}
          <div className='text-left'>
              <h5 className={`font-bold my-4 ml-6 ${headingTextFontSize}`}>Drag the numbers to their position:- </h5>
              <NumberPanel/>
          </div>
        </div>   
      </div>
  </div>
    </div>
    
  
  );
}

export default Clock;
